.infoWizardContainer {
  margin: 0 auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 40px;
  border-radius: 2px;
}

.infoWizardForm {
  margin-top: 20px;
}

