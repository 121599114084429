.ant-layout-content {
  min-height: calc(100vh - 261px) !important;
}

#account-row, #portal-row {
  width: 95%;
  align-self: center;
  overflow-x: hidden;
}

#invoice-table, #subscription-table {
  overflow-y: scroll;
}

.checkout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 4px;
}

.checkout {
  width: 100%;
}

.checkout > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

.checkout .ant-input-group {
  margin-bottom: 20px;
}

.checkout .ant-divider {
  margin-top: 20px;
  margin-bottom: 5px;
}

.payment-confirm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payment-confirm-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.payment-confirm-info h4 {
  margin: 0;
}

#nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s all;
  width: 90%;
  margin: 0 auto;
  max-width: 1260px;
}

#nav button {
  margin-left: 10px;
}

#nav img {
  width: 150px;
}

.content-container {
  width: 70%;
  margin: 0 auto;
  min-height: calc(100vh - 250px - 60px);
  max-width: 960px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.plan-button {
  width: 50%;
}

@media (max-width: 768px) {
  #nav {
    width: 95%;
  }

  .content-container {
    width: 95%;
  }

  .plan-button {
    width: 100%;
  }

  #quick-links {
    display: none;
  }

  #member-flow {
    width: 100%;
  }
}

#footer {
  width: 100%;
  height: 200px;
  background-color: rgba(52, 63, 69, 0.9);
}

#portal-heading {
  padding: 25px;
  padding-left: 0;
}

.section {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 25px;
  margin-bottom: 30px;
}

.section-completed {
  padding: 0px 25px;
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(111, 189, 43, 0.3);
}

.section-completed button {
  color: #6fbd2b !important;
}

.section-completed button:disabled {
  cursor: auto;
}

.section-completed .number {
  font-size: 100px !important;
}

.section .number {
  padding-right: 10px;
  padding-bottom: 20px;
  margin: 0;
  font-size: 200px;
  line-height: 64px;
  color: #e1e1e1e1;
  transition: all 0.6s ease-in;
}

@media screen and (max-width: 500px) {
  .section {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 50px 30px;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }

  .section i {
    margin-top: 80px !important;
    font-size: 42px !important;
  }

  .section-completed i {
    margin-top: 80px !important;
  }

  .section .number {
    font-size: 125px;
    margin-bottom: 10px;
  }

  .section .ant-divider {
    display: none;
  }
}

/* Quicklinks */
  .quicklinks {
    padding-left: 0px;
    margin-top: 1em;
  }

  .quicklinks li {
    list-style: none;
    list-style-image: none;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
  }

  .quicklinks li .anticon {
    margin-right: 5px;
  }

/* Assessment form */

.form-input {
  padding-top: 25px;
  padding-bottom: 25px;
}

.form-container {
  background: white;
}

/* .assessment-question {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
} */

.assessment-question input {
  margin-top: 25px;
  margin-bottom: 10px;
}

.assessment-question input span {
  margin-top: 5px;
}

.assessment-question button {
  text-align: center !important;
  margin-right: 5px;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
}

.StripeElement--focus {
  border: 1px solid #6fbd2b;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
